import './index.scss'
import Swiper from 'swiper/js/swiper.min.js'
var interleaveOffset = 0.5; //视差比值
var bannerswiper = new Swiper('.index-banner .swiper-container', {
    // loop: true,
    speed: 1000,
    watchSlidesProgress: true,
    mousewheelControl: true,
    keyboardControl: true,
    autoplay: {
        delay: 5000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
        waitForTransition: false,
    },
    navigation: {
      nextEl: ".index-banner .swiper-button-next",
      prevEl: ".index-banner .swiper-button-prev",
    },
    pagination: {
        el: '.index-banner .swiper-pagination',
        clickable: true
    },
    on: {
        progress: function (progress) {
            for (var i = 0; i < this.slides.length; i++) {
                var slideProgress = this.slides[i].progress;
                var innerOffset = this.width * interleaveOffset;
                var innerTranslate = slideProgress * innerOffset;
                this.slides[i].querySelector(".slide-inner").style.transform =
                    "translate3d(" + innerTranslate + "px, 0, 0)";
            }
        },
        touchStart: function () {
            for (var i = 0; i < this.slides.length; i++) {
                this.slides[i].style.transition = "";
            }
        },
        setTransition: function () {
            for (var i = 0; i < this.slides.length; i++) {
                this.slides[i].style.transition = "1000ms";
                this.slides[i].querySelector(".slide-inner").style.transition =
                    "1000ms";
            }
        }
    }
});

var swiper = new Swiper(".index-news .swiper-container", {
    navigation: {
        nextEl: ".index-news .swiper-button-next",
        prevEl: ".index-news .swiper-button-prev",
    },
});

$(".index-pro .recommend ul li .mask").mouseenter(function(){
  $(this).children('.desc').fadeIn(300);
})
$(".index-pro .recommend ul li .mask").mouseleave(function(){
  $(this).children('.desc').fadeOut(300);
})
$(document).ready(function(){
    // 视频相关
    var $video = $('.slide-inner video')
    $video.each(function(index){
      this.addEventListener('play', function () { //播放开始执行的函数
        $(this).find('.video-play').hide()
      });
      this.addEventListener('pause', function () { //暂停开始执行的函数
        $(this).find('.video-play').show()
      });
    })
    $('.slide-inner .video-play').on('click',function(){
      let $parent = $(this).siblings('.video')
      $parent.find('video')[0].play()
      $(this).hide()
    })
  })